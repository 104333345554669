/* eslint-disable react/prop-types */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import MKTypography from "components/MKTypography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import Swal from "sweetalert2";
import { Cargando } from "./Cargando";
import InfoIcon from "@mui/icons-material/Info";
import EventBusyIcon from "@mui/icons-material/EventBusy";

export const Libro = ({ carrito, setCarrito }) => {
  const [categorias, setCategorias] = useState([]);
  const [temas, setTemas] = useState([]);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [temas2, setTemas2] = useState([]);

  const [espe, setEspe] = useState("");
  const [opcion, setOpcion] = useState(null);
  const [open3, setOpen3] = useState(false);

  const [dialog, setDialog] = useState(false);

  const [espe2, setEspe2] = useState("");

  const myInit = {
    method: "GET",
  };

  useEffect(() => {
    fetch("https://publimed.net/API/categoriaLibro.php", myInit)
      .then((res) => res.text())
      .then((res) => {
        setCategorias(JSON.parse(res));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function obtener() {
    setDialog(true);
    fetch("https://publimed.net/API/otros.php/?libro", myInit)
      .then((res) => res.text())
      .then((res) => {
        setTemas2([
          ...JSON.parse(res).map((val) => {
            return {
              ...val,
              caracteristicas: val.descripcion.split("-").filter((val2) => val2.length !== 0),
            };
          }),
        ]);
        setDialog(false);
        setOpen2(true);
      })
      .catch((error) => {
        setDialog(false);
        console.log(error);
      });
  }

  function obtenerTemasArticulo(id) {
    setDialog(true);
    fetch("https://publimed.net/API/temasLibro.php", myInit)
      .then((res) => res.text())
      .then((res) => {
        setTemas(
          [
            ...JSON.parse(res).map((val) => {
              return {
                ...val,
                caracteristicas: val.descripcion.split("-").filter((val2) => val2.length !== 0),
              };
            }),
          ].filter((val) => val.id === id)
        );
        setDialog(false);
        setOpen(true);
      })
      .catch((error) => {
        setDialog(false);
        console.log(error);
      });
  }

  function comprar(val2) {
    if (carrito) {
      let datos = carrito.filter(
        (val) => val.tipo === "LIBRO DIGITAL" && val.nombre === val2.nombre
      );
      if (datos.length === 0) {
        setCarrito([...carrito, { ...val2, tipo: "LIBRO DIGITAL" }]);
        localStorage.setItem(
          "cartPublimed",
          JSON.stringify({
            carro: [...carrito, { ...val2, tipo: "LIBRO DIGITAL" }],
          })
        );
      }
      Swal.fire({
        icon: "success",
        text: "Producto Agregado",
      }).then(() => {
        setOpen(false);
      });
    }
  }

  function handle(val) {
    setOpcion(val);
    setOpen3(true);
  }

  function comprar2() {
    if (carrito && opcion) {
      let datos = carrito.filter(
        (val) =>
          val.tipo === "LIBRO DIGITAL" && val.nombre === opcion.nombre && val.especialidad === espe
      );
      if (datos.length === 0) {
        setCarrito([...carrito, { ...opcion, tipo: "LIBRO DIGITAL", especialidad: espe }]);
        localStorage.setItem(
          "cartPublimed",
          JSON.stringify({
            carro: [...carrito, { ...opcion, tipo: "LIBRO DIGITAL", especialidad: espe }],
          })
        );
      }
      Swal.fire({
        icon: "success",
        text: "Producto Agregado",
      }).then(() => {
        setEspe("");
        setOpcion(null);
        setOpen3(false);
        setOpen2(false);
      });
    }
    setEspe("");
    setOpcion(null);
    setOpen3(false);
    setOpen2(false);
  }

  return (
    <>
      <Paper
        elevation={2}
        sx={{
          padding: "10px",
          // , height: 350, overflowY: "scroll"
        }}
      >
        <MKTypography variant="h5"> Libro Digital </MKTypography>
        <br />
        <Grid container spacing={1}>
          <Grid item xs={12} lg={6}>
            <MKTypography variant="h6"> Autor/Coautor: </MKTypography>

            {categorias &&
              categorias.map((val) => (
                // <MKTypography key={val.id} variant="body2">
                //   - {val.nombre}
                // </MKTypography>
                <Button
                  key={val.id}
                  fullWidth
                  onClick={() => {
                    setEspe2(val.nombre);
                    obtenerTemasArticulo(val.id);
                  }}
                >
                  <InfoIcon sx={{ marginRight: "10px" }} fontSize="large" color="info" />
                  <MKTypography key={val.id} variant="body2">
                    {val.nombre}
                  </MKTypography>
                </Button>
              ))}
            {/* <Button
              variant="contained"
              sx={{ color: "white !important" }}
              onClick={() => obtenerTemasArticulo()}
            >
              {" "}
              Ver más
            </Button> */}
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKTypography variant="h6"> Autor Único: </MKTypography>
            <Button
              variant="contained"
              onClick={() => obtener()}
              sx={{ color: "white !important" }}
            >
              {" "}
              Ver más
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setTemas([]);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Card>
            <CardContent>
              <MKTypography variant="h5" sx={{ textAlign: "center" }}>
                {" "}
                Artículo Médico - Autor/Coautor{" "}
              </MKTypography>
              <MKTypography variant="h6" sx={{ textAlign: "center" }}>
                {" "}
                Temas Disponibles - {espe2}{" "}
              </MKTypography>
              <br />
              {temas &&
                temas.map((val) => (
                  <Accordion key={val.id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="large" />}>
                      <p>
                        <strong>Tema:</strong> {val.nombre}
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <p>
                            <strong>Características:</strong>
                          </p>
                          {val.caracteristicas &&
                            val.caracteristicas.map((valor) => (
                              <MKTypography key={valor} variant="body2">
                                - {valor}
                              </MKTypography>
                            ))}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack direction={"column"} spacing={2}>
                            <p>
                              <strong>Especialidad:</strong> {val.categoriaNombre}
                            </p>
                            {val.lleno !== 1 && (
                              <p>
                                <strong>Cupos Disponibles!!!</strong>
                              </p>
                            )}
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "darkOrange !important",
                                color: "white !important",
                              }}
                            >
                              {" "}
                              Precio: ${val.precio}.00
                            </Button>
                            {val.lleno === 1 ? (
                              <Button
                                variant="contained"
                                disabled
                                sx={{
                                  backgroundColor: "darkGreen !important",
                                  color: "white !important",
                                }}
                              >
                                LLENO <EventBusyIcon />
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                onClick={() => comprar(val)}
                                sx={{
                                  backgroundColor: "darkGreen !important",
                                  color: "white !important",
                                }}
                              >
                                {" "}
                                Agregar <LocalGroceryStoreIcon />
                              </Button>
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
              setTemas([]);
            }}
            sx={{ backgroundColor: "darkRed !important", color: "white !important" }}
          >
            {" "}
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open2}
        onClose={() => {
          setOpen2(false);
          setTemas2([]);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Card>
            <CardContent>
              <MKTypography variant="h5" sx={{ textAlign: "center" }}>
                {" "}
                Libro Digital - Autor Único{" "}
              </MKTypography>
              <br />
              {temas2 &&
                temas2.map((val) => (
                  <Accordion key={val.id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon fontSize="large" />}>
                      <p>
                        <strong> {val.nombre}</strong>
                      </p>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <p>
                            <strong>Características:</strong>
                          </p>
                          {val.caracteristicas &&
                            val.caracteristicas.map((valor) => (
                              <MKTypography key={valor} variant="body2">
                                - {valor}
                              </MKTypography>
                            ))}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Stack direction={"column"} spacing={2}>
                            <Button
                              variant="contained"
                              sx={{
                                backgroundColor: "darkOrange !important",
                                color: "white !important",
                              }}
                            >
                              {" "}
                              Precio: ${val.precio}.00
                            </Button>
                            <Button
                              variant="contained"
                              onClick={() => handle(val)}
                              sx={{
                                backgroundColor: "darkGreen !important",
                                color: "white !important",
                              }}
                            >
                              {" "}
                              Agregar <LocalGroceryStoreIcon />
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setOpen2(false);
              setTemas2([]);
            }}
            sx={{ backgroundColor: "darkRed !important", color: "white !important" }}
          >
            {" "}
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open3}
        onClose={() => {
          setOpen3(false);
          setEspe("");
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Card>
            <CardContent>
              <MKTypography variant="h5" sx={{ textAlign: "center" }}>
                {" "}
                Ingrese la Especialidad{" "}
              </MKTypography>
              <br />
              <TextField
                fullWidth
                value={espe}
                onChange={(ev) => setEspe(ev.target.value)}
                size="small"
              />
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={espe.length === 0}
            onClick={() => {
              comprar2();
            }}
            sx={{ backgroundColor: "darkGreen !important", color: "white !important" }}
          >
            {" "}
            Guardar
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setOpen3(false);
              setEspe("");
            }}
            sx={{ backgroundColor: "darkRed !important", color: "white !important" }}
          >
            {" "}
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Cargando open2={dialog} />
    </>
  );
};
