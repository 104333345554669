/* eslint-disable react/prop-types */
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import MKTypography from "components/MKTypography";
import React from "react";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import Swal from "sweetalert2";
import { useState } from "react";

export const Otros = ({ dato, carrito, setCarrito }) => {
  const [opcion, setOpcion] = useState(null);
  const [espe, setEspe] = useState("");
  const [tiempo, setTiempo] = useState("");
  const [open3, setOpen3] = useState(false);

  function handle(val2) {
    if (val2.tipo === "CERTIFICADO DE CONGRESO") {
      let datos = carrito.filter(
        (val) => val.tipo === "CERTIFICADO DE CONGRESO" && val.nombre === val2.nombre
      );
      if (datos.length === 0) {
        setCarrito([...carrito, { ...val2 }]);
        localStorage.setItem(
          "cartPublimed",
          JSON.stringify({
            carro: [...carrito, { ...val2 }],
          })
        );
      }
      Swal.fire({
        icon: "success",
        text: "Producto Agregado",
      }).then(() => {
        setOpen3(false);
      });
    } else {
      setOpcion(val2);
      setOpen3(true);
    }
  }

  function comprar2() {
    if (opcion) {
      let datos = carrito.filter(
        (val) =>
          val.tipo === opcion.tipo &&
          val.nombre === opcion.nombre &&
          (val.tema === espe || val.especialidad === espe)
      );
      if (datos.length === 0) {
        if (opcion.tipo.includes("CERTIFICADO")) {
          setCarrito([...carrito, { ...opcion, especialidad: espe }]);
          localStorage.setItem(
            "cartPublimed",
            JSON.stringify({
              carro: [...carrito, { ...opcion, especialidad: espe }],
            })
          );
        } else {
          setCarrito([...carrito, { ...opcion, tema: espe, tiempo: tiempo }]);
          localStorage.setItem(
            "cartPublimed",
            JSON.stringify({
              carro: [...carrito, { ...opcion, tema: espe, tiempo: tiempo }],
            })
          );
        }
      }
      Swal.fire({
        icon: "success",
        text: "Producto Agregado",
      }).then(() => {
        setEspe("");
        setTiempo("");
        setOpcion(null);
        setOpen3(false);
      });
    }
    setEspe("");
    setTiempo("");
    setOpcion(null);
    setOpen3(false);
  }

  return (
    <>
      <Grid item xs={12} md={4}>
        <Paper
          elevation={2}
          sx={{
            padding: "10px",
            // , height: 350, overflowY: "scroll"
          }}
        >
          <MKTypography variant="h5" sx={{ textAlign: "center" }}>
            {" "}
            {dato.nombre}{" "}
          </MKTypography>
          <br />
          {dato.caracteristicas &&
            dato.caracteristicas.map((valor) => (
              <MKTypography key={valor} variant="body2">
                - {valor}
              </MKTypography>
            ))}
          <Stack direction={"column"} spacing={1}>
            <Button
              variant="contained"
              sx={{
                backgroundColor: "darkOrange !important",
                color: "white !important",
              }}
            >
              {" "}
              Precio: ${dato.precio}.00
            </Button>
            <Button
              variant="contained"
              onClick={() => handle(dato)}
              sx={{
                backgroundColor: "darkGreen !important",
                color: "white !important",
              }}
            >
              {" "}
              Agregar <LocalGroceryStoreIcon />
            </Button>
          </Stack>
        </Paper>
      </Grid>

      <Dialog
        open={open3}
        onClose={() => {
          setOpen3(false);
          setEspe("");
          setTiempo("");
        }}
        fullWidth
        maxWidth="xs"
      >
        <DialogContent>
          <Card>
            <CardContent>
              {opcion && !opcion.tipo.includes("CERTIFICADO") ? (
                <MKTypography variant="h5" sx={{ textAlign: "center" }}>
                  {" "}
                  Ingrese el Tema{" "}
                </MKTypography>
              ) : (
                <MKTypography variant="h5" sx={{ textAlign: "center" }}>
                  {" "}
                  Ingrese la Especialidad{" "}
                </MKTypography>
              )}
              <br />
              <TextField
                fullWidth
                value={espe}
                onChange={(ev) => setEspe(ev.target.value)}
                size="small"
              />
              {opcion && !opcion.tipo.includes("CERTIFICADO") && (
                <>
                  <br />
                  <br />
                  <MKTypography variant="h5" sx={{ textAlign: "center" }}>
                    {" "}
                    Ingrese la Tiempo de entrega{" "}
                  </MKTypography>
                  <br />
                  <TextField
                    fullWidth
                    value={tiempo}
                    onChange={(ev) => setTiempo(ev.target.value)}
                    size="small"
                  />
                </>
              )}
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={
              espe.length === 0 ||
              (opcion && !opcion.tipo.includes("CERTIFICADO") && tiempo.length === 0)
            }
            onClick={() => {
              comprar2();
            }}
            sx={{ backgroundColor: "darkGreen !important", color: "white !important" }}
          >
            {" "}
            Guardar
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setOpen3(false);
              setEspe("");
              setTiempo("");
            }}
            sx={{ backgroundColor: "darkRed !important", color: "white !important" }}
          >
            {" "}
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
