import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// PUBLIMED React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// PUBLIMED React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/LOGO.jpeg";
import team2 from "assets/images/LOGO.jpeg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Metas
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                position={{ color: "info", label: "" }}
                description="Contribuir con la salud dando información
                 actualizada de patologías, métodos de diagnósticos, procedimientos,
                  y todo lo que conlleva a la práctica médica."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team2}
                position={{ color: "info", label: "" }}
                description="Aumentar participantes en nuestras
                 publicaciones como investigadores de la salud, así obtener
                  mayor conocimiento de profesionales de diferentes partes del mundo."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
