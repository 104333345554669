import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/LOGO.jpeg";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "PUBLIMED",
    image: logoCT,
    route: "/",
  },
  // socials: [
  //   {
  //     icon: <FacebookIcon />,
  //     link: "https://www.facebook.com/CreativeTim/",
  //   },
  //   {
  //     icon: <TwitterIcon />,
  //     link: "https://twitter.com/creativetim",
  //   },
  //   {
  //     icon: <GitHubIcon />,
  //     link: "https://github.com/creativetimofficial",
  //   },
  //   {
  //     icon: <YouTubeIcon />,
  //     link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
  //   },
  // ],
  menus: [
    {
      name: "Redes Sociales",
      items: [
        { name: "Instagram", href: "https://www.instagram.com/publimed2/" },
        { name: "TikTok", href: "https://www.tiktok.com/@publimed2" },
        { name: "Facebook", href: "https://www.facebook.com/profile.php?id=100093661928867" },
      ],
    },
    {
      name: "Contacto",
      items: [
        { name: "WhatsApp: 0993490200 – 0994333370" },
        { name: "Correo: publimed87@gmail.com" },
        { name: "País: Ecuador" },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Material Kit by{" "}
      <MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Creative Tim
      </MKTypography>
      .
    </MKTypography>
  ),
};
