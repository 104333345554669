import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// PUBLIMED React components
import MKBox from "components/MKBox";

// PUBLIMED React examples
import RotatingCard from "examples/Cards/RotatingCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/ASESORIA.jpeg";
import bgBack from "assets/images/ARTICULOS.jpeg";
import MKTypography from "components/MKTypography";
import { useNavigate } from "react-router-dom";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useState } from "react";
import { Libro } from "pages/Store/Libro";
import { Articulo } from "pages/Store/Articulo";
import { useEffect } from "react";
import { Otros } from "pages/Store/Otros";
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";
import Divider from "@mui/material/Divider";
function Information() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [tipo, setTipo] = useState("");
  const [carrito, setCarrito] = useState([]);
  const [elementos, setElementos] = useState([]);

  const [cantidad, setCantidad] = useState(0);
  const [cantidad2, setCantidad2] = useState(0);
  const [cantidad3, setCantidad3] = useState(0);

  useEffect(() => {
    if (localStorage.getItem("cartPublimed")) {
      setCarrito(JSON.parse(localStorage.getItem("cartPublimed")).carro);
    }
    fetch("https://publimed.net/API/cantidad.php", {
      method: "GET",
    })
      .then((res) => res.text())
      .then((res) => {
        setCantidad(JSON.parse(res)[0].cantidad);
        setCantidad2(JSON.parse(res)[0].libros);
        setCantidad3(JSON.parse(res)[0].ariculos);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const obtener = (tipo) => {
    fetch("https://publimed.net/API/otros.php/?extra", {
      method: "GET",
    })
      .then((res) => res.text())
      .then((res) => {
        const datos = [
          ...JSON.parse(res).map((val) => {
            return {
              ...val,
              caracteristicas: val.descripcion.split("-").filter((val2) => val2.length !== 0),
            };
          }),
        ].filter((val) => val.tipo === tipo);
        setElementos(datos);
      })
      .catch((error) => {
        console.log(error);
      });
    setOpen(true);
    setTipo(tipo);
  };

  return (
    <>
      <MKBox component="section" py={3}>
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={9}
            sx={{ mx: "auto", alignItems: "center", display: "flex", justifyContent: "center" }}
          >
            <Grid item xs={12} md={3}>
              <DefaultCounterCard
                count={cantidad}
                suffix="+"
                title="Profesionales"
                description="Que han participado con nosotros."
              />
            </Grid>
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <Grid item xs={12} md={3}>
              <DefaultCounterCard
                count={cantidad2}
                suffix="+"
                title="Libros Médicos"
                description="Publicados por Nosotros"
              />
            </Grid>
            <Divider orientation="vertical" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
            <Grid item xs={12} md={3}>
              <DefaultCounterCard
                count={cantidad3}
                suffix="+"
                title="Artículos Médicos"
                description="Publicados por Nosotros"
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      <MKBox component="section" py={6} my={6}>
        <Container>
          <Grid container item xs={12} spacing={3} alignItems="center">
            <Grid item xs={12} lg={5}>
              <RotatingCard>
                <MKBox
                  component="img"
                  src={bgFront}
                  alt={"title"}
                  borderRadius="lg"
                  width="100%"
                  position="relative"
                  zIndex={1}
                  sx={{
                    backgroundSize: "cover",
                    backfaceVisibility: "hidden",
                  }}
                />
                <MKBox
                  component="img"
                  src={bgBack}
                  position="absolute"
                  width="100%"
                  top={0}
                  left={0}
                  zIndex={1}
                  sx={{
                    backgroundSize: "cover",
                    backfaceVisibility: "hidden",
                    transform: "rotateY(180deg)",
                  }}
                />
              </RotatingCard>
            </Grid>
            <Grid item xs={12} lg={7}>
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={12}>
                  <DefaultInfoCard
                    icon="content_copy"
                    title="¿Qué ofrecemos?"
                    direction="center"
                    description=""
                    sx={{ width: "100%" }}
                  />
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                  {[
                    "Artículo Médico",
                    "Libro Científico Digital",
                    "Certificado de Congreso",
                    "Certificado de Ponencia",
                    "Trabajo de Investigación",
                    "Tesis de Pregrado",
                    "Tesis de Posgrado",
                    "Anteproyecto",
                  ].map((val) => (
                    <Button
                      key={val}
                      fullWidth
                      onClick={() => {
                        obtener(val.toUpperCase());
                      }}
                    >
                      <MKTypography key={val} variant="body2">
                        {val}
                      </MKTypography>
                    </Button>
                  ))}
                  {/* <MKTypography variant="body2" opacity={0.8} mb={2}>
                  - Artículo Médico <br />
                  - Libro Científico Digital
                  <br />
                  - Certificado de Congreso
                  <br />- Certificado de Ponencia
                  <br />
                  - Trabajo de Investigación
                  <br />
                  - Publicación de Artículos Médicos
                  <br />
                  - Tesis de Pregrado
                  <br />
                  - Tesis de Posgrado
                  <br />- Anteproyecto
                </MKTypography> */}
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                  <Button
                    variant="contained"
                    onClick={() => navigate("/tienda")}
                    sx={{ color: "white !important" }}
                  >
                    {" "}
                    Ver productos
                  </Button>
                </Grid>
                <Grid item xs={12} textAlign={"center"}>
                  <Button
                    variant="contained"
                    onClick={() => {
                      window.open("https://clientes.publimed.net/", "_blank");
                    }}
                    sx={{ marginTop: "20px", color: "white !important" }}
                  >
                    {" "}
                    Portal de Clientes
                  </Button>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  title="MUI Ready"
                  description="The world's most popular react components library for building user interfaces."
                />
              </Grid> */}
              </Grid>
              {/* <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  title="Save Time & Money"
                  description="Creating your design from scratch with dedicated designers can be very expensive. Start with our Design System."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  title="Fully Responsive"
                  description="Regardless of the screen size, the website content will naturally fit the given resolution."
                />
              </Grid>
            </Grid> */}
            </Grid>
          </Grid>
        </Container>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            {tipo.includes("LIBRO") && <Libro setCarrito={setCarrito} carrito={carrito} />}
            {tipo.includes("ARTÍCULO") && <Articulo setCarrito={setCarrito} carrito={carrito} />}
            {elementos &&
              elementos.map((val) => (
                <Otros key={val.id} dato={val} setCarrito={setCarrito} carrito={carrito} />
              ))}
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => {
                setOpen(false);
              }}
              sx={{ backgroundColor: "darkRed !important", color: "white !important" }}
            >
              {" "}
              Cerrar
            </Button>
          </DialogActions>
        </Dialog>
      </MKBox>
    </>
  );
}

export default Information;
