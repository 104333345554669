/* eslint-disable react/prop-types */
/* eslint-disable react/no-unknown-property */
import { CircularProgress, Dialog, DialogTitle, Stack } from "@mui/material";
import React from "react";

export const Cargando = ({ open2 }) => {
  return (
    <Dialog open={open2}>
      <DialogTitle>
        <Stack spacing={2} style={{ alignItems: "center", justifyContent: "center" }}>
          <CircularProgress align="center" />
          <strong>
            <p align="center" style={{ fontSize: "14px" }}>
              CARGANDO...
            </p>
          </strong>
        </Stack>
      </DialogTitle>
    </Dialog>
  );
};
