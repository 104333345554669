import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// PUBLIMED React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// PUBLIMED React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg-coworking.jpeg";
import {
  Button,
  Paper,
  Stack,
  IconButton,
  Box,
  DialogActions,
  TextField,
  CardContent,
  DialogContent,
  Dialog,
  // TableContainer,
  // Table,
  // TableHead,
  // TableRow,
  // TableCell,
  // TableBody,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import { Articulo } from "./Articulo";
import { Libro } from "./Libro";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, esES } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import { Cargando } from "./Cargando";
import { Otros } from "./Otros";
import { useParams } from "react-router-dom";

// function Tienda() {
export const Tienda = () => {
  const params = useParams();
  const [elementos, setElementos] = useState([]);

  const [carrito, setCarrito] = useState([]);

  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [celular, setCelular] = useState("");
  const [cedula, setCedula] = useState("");
  const [profesion, setProfesion] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [direccion, setDireccion] = useState("");
  const [lugar, setLugar] = useState("");
  const [orcid, setOrcid] = useState("");
  const [universidad, setUniversidad] = useState("");

  const [open2, setOpen2] = useState(false);

  const [dialog, setDialog] = useState(false);
  const myInit = {
    method: "GET",
  };

  // function limpiar() {
  //   setNombre("");
  //   setCorreo("");
  //   setCelular("");
  //   setProfesion("");
  //   setCiudad("");
  //   setDireccion("");
  //   setNombre("");
  //   setLugar("");
  //   setOrcid("");
  //   setUniversidad("");
  //   setCedula("");
  //   localStorage.removeItem("cartPublimed");
  //   setCarrito([]);
  // }

  function generateP() {
    var pass = "";
    var str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ" + "abcdefghijklmnopqrstuvwxyz0123456789@#$";

    for (let i = 1; i <= 8; i++) {
      var char = Math.floor(Math.random() * str.length + 1);

      pass += str.charAt(char);
    }

    return pass;
  }

  function comprar(id) {
    let total = carrito.reduce((a, b) => a + b.precio, 0);
    let body = JSON.stringify({
      amount: total + "00",
      amountWithoutTax: total + "00",
      clientTransactionID: id,
      responseUrl: "https://publimed.net/API/respuesta.php",
      cancellationUrl: "https://publimed.net/API/respuesta.php",
    });
    let headers2 = new Headers();
    headers2.append(
      "Authorization",
      "Bearer VpzrMpGIIl5FA3Z2mwiYMj28xILyWQItnNiYJyFNwYgW60Iz83xnUuN66LK7cSmNu23jU0Afuu-e_sjX6ep8sB9NppsMnWWJmcTAHGepLVpdA8q7xTBhBRn0Zba8Jg3-_ow8E-9b3XxKjfxLaSgsj4vxCBlwmflkaQNwHU2XxGZHSoufzwwxXme4TAD0SKwjsTfus3nLqxYAaNIYiXBfTnwamkQZjzDI0q_isqzuCcGSTpp1kcSmDozIXhyWgqLgx5q5hWOLjsnnKYvTqpDTmQUx1Odf04V29TJHZJCKonoOG2nsKoX9x3eqXoY-JYwpl5_O1g"
    );
    headers2.append("Content-Type", "application/json");
    let options = {
      method: "POST",
      headers: headers2,
      body: body,
    };
    fetch("https://pay.payphonetodoesposible.com/api/button/Prepare", options)
      .then((res) => res.text())
      .then((res) => {
        setDialog(false);
        console.log(JSON.parse(res));
        location.href = JSON.parse(res).payWithCard;
      })
      .catch((error) => {
        setDialog(false);
        console.error(error);
        Swal.fire({
          icon: "error",
          text: error,
        });
      });
  }

  function enviar() {
    var contra = generateP();
    setDialog(true);
    let formData2 = new FormData();
    formData2.append("nombre", nombre.trim());
    formData2.append("correo", correo.trim());
    formData2.append("cedula", cedula.trim());
    formData2.append("celular", celular.trim());
    formData2.append("direccion", direccion.trim());
    formData2.append("ciudad", ciudad.trim());
    formData2.append("lugar", lugar.trim());
    formData2.append("profesion", profesion.trim());
    formData2.append("orcid", orcid.trim());
    formData2.append("universidad", universidad.trim());
    formData2.append("contrasena", contra);
    fetch("https://publimed.net/API/usuarios.php/", {
      method: "POST",
      body: formData2,
    })
      .then((res) => res.text())
      .then(async (res) => {
        let resp = JSON.parse(res);
        if (resp.length !== 1) {
          setDialog(false);
          Swal.fire({
            icon: "error",
            text: "Ocurrió un error al crear el usuario",
          });
          return;
        }
        let trans = "P_" + resp[0].id;
        let lista = [];
        for (const el of carrito) {
          let formData = new FormData();
          formData.append("nombre", el.nombre.trim());
          formData.append("precio", el.precio);
          formData.append("tipo", el.tipo.trim());
          formData.append("usuario", correo.trim());
          formData.append("tema", el.tema ? el.tema.trim() : "");
          formData.append("especialidad", el.especialidad ? el.especialidad.trim() : "");
          formData.append("tiempo", el.tiempo ? el.tiempo.trim() : "");
          formData.append("transaccion", trans);
          await fetch("https://publimed.net/API/compra.php/", {
            method: "POST",
            body: formData,
          })
            .then((res) => res.text())
            .then((res) => {
              console.log(res);
              lista.push(res);
            })
            .catch((error) => {
              console.log(error);
              Swal.fire({
                icon: "error",
                text: error,
              });
              return;
            });
        }

        if (lista.length === carrito.length) {
          comprar(trans);
        } else {
          setDialog(false);
          Swal.fire({
            icon: "error",
            text: "Ocurrio un error",
          });
          return;
        }
      })
      .catch((error) => {
        setDialog(false);
        console.log(error);
        Swal.fire({
          icon: "error",
          text: error,
        });
        return;
      });
  }

  useEffect(() => {
    fetch("https://publimed.net/API/otros.php/?extra", myInit)
      .then((res) => res.text())
      .then((res) => {
        setElementos([
          ...JSON.parse(res).map((val) => {
            return {
              ...val,
              caracteristicas: val.descripcion.split("-").filter((val2) => val2.length !== 0),
            };
          }),
        ]);
      })
      .catch((error) => {
        console.log(error);
        Swal.fire({
          icon: "error",
          text: error,
        });
      });

    if (params.id) {
      if (params.id === "error") {
        Swal.fire({
          icon: "error",
          text: "Hubo un error en su transacción, revise e intente nuevamente.",
        }).then(() => {
          location.href = "https://publimed.net/tienda";
        });
      } else {
        Swal.fire({
          icon: "success",
          text:
            "Gracias por participar con PUBLIMED, visite el sitio web " +
            "https://clientes.publimed.net/ donde el proceso diario de su compra. " +
            "Su usuario es: " +
            params.id.split("&&")[0] +
            ", su contraseña es: " +
            params.id.split("&&")[1],
        }).then(() => {
          localStorage.removeItem("cartPublimed");
          location.href = "https://publimed.net/tienda";
        });
      }
    }

    if (localStorage.getItem("cartPublimed")) {
      validar();
    }
  }, []);

  async function validar() {
    let lista = [];
    await fetch("https://publimed.net/API/otros.php/?extra", myInit)
      .then((res) => res.text())
      .then((res) => {
        lista = lista.concat([
          ...JSON.parse(res).map((val) => {
            return {
              ...val,
              tipo: val.nombre,
            };
          }),
        ]);
      })
      .catch((error) => {
        console.error(error);
      });
    await fetch("https://publimed.net/API/otros.php/?libro", myInit)
      .then((res) => res.text())
      .then((res) => {
        lista = lista.concat([
          ...JSON.parse(res).map((val) => {
            return {
              ...val,
              tipo: "LIBRO DIGITAL",
            };
          }),
        ]);
      })
      .catch((error) => {
        console.error(error);
      });
    await fetch("https://publimed.net/API/otros.php/?articulo", myInit)
      .then((res) => res.text())
      .then((res) => {
        lista = lista.concat([
          ...JSON.parse(res).map((val) => {
            return {
              ...val,
              tipo: "ARTICULO MEDICO",
            };
          }),
        ]);
      })
      .catch((error) => {
        console.error(error);
      });
    await fetch("https://publimed.net/API/temasLibro.php", myInit)
      .then((res) => res.text())
      .then((res) => {
        lista = lista.concat([
          ...JSON.parse(res)
            .filter((val) => val.lleno !== 1)
            .map((val) => {
              return {
                ...val,
                tipo: "LIBRO DIGITAL",
              };
            }),
        ]);
      })
      .catch((error) => {
        console.error(error);
      });
    await fetch("https://publimed.net/API/temasArticulo.php", myInit)
      .then((res) => res.text())
      .then((res) => {
        lista = lista.concat([
          ...JSON.parse(res)
            .filter((val) => val.lleno !== 1)
            .map((val) => {
              return {
                ...val,
                tipo: "ARTICULO MEDICO",
              };
            }),
        ]);
      })
      .catch((error) => {
        console.error(error);
      });
    let carro = JSON.parse(localStorage.getItem("cartPublimed")).carro;
    let lista2 = [];
    console.log(lista);
    console.log(carro);
    for (const el of carro) {
      if (lista.filter((val) => val.nombre === el.nombre && val.tipo === el.tipo).length > 0) {
        lista2.push(el);
      }
    }
    if (lista2.length !== carro.length) {
      Swal.fire({
        icon: "info",
        text: "Se han eliminado artículos del carrito porque ya no se encuentran disponibles",
      });
    }
    localStorage.setItem(
      "cartPublimed",
      JSON.stringify({
        carro: lista2,
      })
    );
    // setCarrito(JSON.parse(localStorage.getItem("cartPublimed")).carro);
    setCarrito(lista2);
  }

  const columns = [
    {
      field: "nombre",
      headerClassName: "super-app-theme--header",
      headerName: "Producto",
      flex: 1.5,
      renderCell: (params) => (
        <a>
          <strong>{params.row.tipo} -</strong>
          {params.row.nombre}{" "}
          {params.row.especialidad ? (
            <>
              {" "}
              - <strong>Especialidad:</strong> {params.row.especialidad}
            </>
          ) : (
            ""
          )}
          {params.row.tema ? (
            <>
              {" "}
              - <strong>Tema:</strong> {params.row.tema}
            </>
          ) : (
            ""
          )}{" "}
        </a>
      ),
    },
    {
      field: "precio",
      headerClassName: "super-app-theme--header",
      headerName: "Precio",
      flex: 0.5,
      renderCell: (params) => <a>${params.row.precio}.00</a>,
    },
    {
      field: "responsable",
      headerClassName: "super-app-theme--header",
      headerName: "",
      flex: 0.5,
      renderCell: (params) => (
        <IconButton onClick={() => handleDelete(params.row)}>
          <DeleteIcon color="error" />
        </IconButton>
      ),
    },
  ];

  function handleDelete(val2) {
    let lista = [];
    let index = 0;
    while (index < carrito.length) {
      if (index !== val2.id) {
        lista.push(carrito[index]);
      }
      index += 1;
    }
    setCarrito(lista);
    localStorage.setItem(
      "cartPublimed",
      JSON.stringify({
        carro: lista,
      })
    );
    Swal.fire({
      icon: "success",
      text: "Producto Eliminado",
    }).then(() => {});
  }

  function datosPer() {
    setOpen2(true);
  }

  return (
    <>
      <DefaultNavbar routes={routes} sticky />
      <MKBox
        minHeight="50vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              TIENDA
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <MKTypography sx={{ textAlign: "center" }} variant="h3">
              {" "}
              Productos{" "}
            </MKTypography>{" "}
            <br />
            <Stack spacing={3} direction={"row"}>
              {" "}
              <MKTypography>
                <strong>Portal de Clientes </strong>
              </MKTypography>
              <Button
                variant="contained"
                onClick={() => {
                  window.open("https://clientes.publimed.net/", "_blank");
                }}
                sx={{ alignSelf: "center", marginLeft: "20px", color: "white !important" }}
              >
                {" "}
                Visitar
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <Articulo setCarrito={setCarrito} carrito={carrito} />
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
            <Libro setCarrito={setCarrito} carrito={carrito} />
          </Grid>
          {elementos &&
            elementos.map((val) => (
              <Otros key={val.id} dato={val} setCarrito={setCarrito} carrito={carrito} />
            ))}
        </Grid>
        <br />
        <MKBox
          component="section"
          variant="gradient"
          bgColor="dark"
          position="relative"
          py={6}
          px={{ xs: 2, lg: 0 }}
          mx={-2}
        >
          <Container>
            <Grid container>
              <Grid item xs={12} sx={{ mb: 6 }}>
                <MKTypography variant="h3" color="white">
                  Carrito: {carrito.length === 0 && "Vacío"}
                </MKTypography>
                {carrito.length !== 0 && (
                  <>
                    <Paper elevation={2} sx={{ overflowX: "scroll", padding: "10px" }}>
                      <Box
                        sx={{
                          height: 300,
                          minWidth: 500,
                          "& .super-app-theme--header": {
                            backgroundColor: "#428bd4",
                            color: "white",
                            fontSize: "16px",
                          },
                        }}
                      >
                        <DataGrid
                          rows={
                            [
                              ...carrito.map((val, index) => {
                                return { ...val, id: index };
                              }),
                            ] ?? []
                          }
                          columns={columns}
                          hideFooter
                          disableColumnMenu
                          disableColumnFilter
                          disableRowSelectionOnClick
                          disableColumnSelector
                          getRowId={(row) => row.id + row.nombre + row.especialidad + row.tema}
                          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                          getRowHeight={() => "auto"}
                          getEstimatedRowHeight={() => 100}
                        />
                      </Box>
                    </Paper>
                    {/* <TableContainer component={Paper} sx={{ width: "100%" }}>
                      <Table sx={{ minWidth: 650 }} size="small">
                        <TableHead>
                          <TableRow sx={{ width: "100%" }}>
                            <TableCell align="right">Calories</TableCell>
                            <TableCell align="right">Fat</TableCell>
                            <TableCell align="right">Carbs</TableCell>
                            <TableCell align="right">Protein</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                            <TableCell colSpan={4} align="right">
                              row.calories
                            </TableCell>
                            <TableCell colSpan={4} align="right">
                              row.fat
                            </TableCell>
                            <TableCell colSpan={4} align="right">
                              row.carbs
                            </TableCell>
                            <TableCell colSpan={4} align="right">
                              row.protein
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer> */}
                  </>
                )}
              </Grid>
              <Grid item xs={12} sx={{ mb: 6 }}>
                <Stack spacing={2} direction={"row"}>
                  <MKTypography variant="h3" color="white">
                    Total: $ {carrito.reduce((a, b) => a + b.precio, 0)}.00
                  </MKTypography>
                  {carrito.length !== 0 && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        datosPer();
                      }}
                      disabled={carrito.length === 0}
                      sx={{
                        backgroundColor: "darkGreen !important",
                        color: "white !important",
                      }}
                    >
                      {" "}
                      CONTINUAR
                    </Button>
                  )}
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>

      <Dialog
        open={open2}
        onClose={() => {
          setOpen2(false);
          // limpiar();
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent>
          <Card>
            <CardContent>
              <MKTypography variant="h5" sx={{ textAlign: "center" }}>
                {" "}
                Datos Personales{" "}
              </MKTypography>

              <br />
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <p>
                    <strong>Nombre Completo: *</strong>
                  </p>
                  <TextField
                    fullWidth
                    value={nombre}
                    onChange={(ev) => setNombre(ev.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <p>
                    <strong>Correo: *</strong>
                  </p>
                  <TextField
                    fullWidth
                    value={correo}
                    onChange={(ev) => setCorreo(ev.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <p>
                    <strong>Cédula: *</strong>
                  </p>
                  <TextField
                    fullWidth
                    value={cedula}
                    onChange={(ev) => setCedula(ev.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <p>
                    <strong>Celular: *</strong>
                  </p>
                  <TextField
                    fullWidth
                    value={celular}
                    onChange={(ev) => setCelular(ev.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <p>
                    <strong>Ciudad: *</strong>
                  </p>
                  <TextField
                    fullWidth
                    value={ciudad}
                    onChange={(ev) => setCiudad(ev.target.value)}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <p>
                    <strong>Profesión: *</strong>
                  </p>
                  <TextField
                    fullWidth
                    value={profesion}
                    onChange={(ev) => setProfesion(ev.target.value)}
                    size="small"
                  />
                </Grid>
                {carrito.filter(
                  (val) => !val.tipo.includes("LIBRO") && !val.tipo.includes("ARTICULO")
                ).length !== 0 && (
                  <>
                    <Grid item xs={12} lg={6}>
                      <p>
                        <strong>Dirección de lugar de entrega: *</strong>
                      </p>
                      <TextField
                        fullWidth
                        value={direccion}
                        onChange={(ev) => setDireccion(ev.target.value)}
                        size="small"
                      />
                    </Grid>
                  </>
                )}
                {carrito.filter(
                  (val) => val.tipo.includes("LIBRO") || val.tipo.includes("ARTICULO")
                ).length !== 0 && (
                  <>
                    <Grid item xs={12} lg={6}>
                      <p>
                        <strong>Lugar de Trabajo: *</strong>
                      </p>
                      <TextField
                        fullWidth
                        value={lugar}
                        onChange={(ev) => setLugar(ev.target.value)}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <p>
                        <strong>Universidad de Graduación: *</strong>
                      </p>
                      <TextField
                        fullWidth
                        value={universidad}
                        onChange={(ev) => setUniversidad(ev.target.value)}
                        size="small"
                      />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <p>
                        <strong>ORCID: </strong>
                        {"(opcional) "}
                        <a href="https://orcid.org/register" target="_blank" rel="noreferrer">
                          orcid.org/register
                        </a>
                      </p>
                      <TextField
                        fullWidth
                        value={orcid}
                        onChange={(ev) => setOrcid(ev.target.value)}
                        size="small"
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            disabled={
              nombre.length === 0 ||
              cedula.length === 0 ||
              celular.length === 0 ||
              correo.length === 0 ||
              profesion.length === 0 ||
              ciudad.length === 0 ||
              (carrito.filter(
                (val) => !val.tipo.includes("LIBRO") && !val.tipo.includes("ARTICULO")
              ).length !== 0 &&
                direccion.length === 0) ||
              (carrito.filter((val) => val.tipo.includes("LIBRO") || val.tipo.includes("ARTICULO"))
                .length !== 0 &&
                lugar.length === 0) ||
              (carrito.filter((val) => val.tipo.includes("LIBRO") || val.tipo.includes("ARTICULO"))
                .length !== 0 &&
                universidad.length === 0)
            }
            onClick={() => {
              setOpen2(false);
              enviar();
            }}
            sx={{ backgroundColor: "darkGreen !important", color: "white !important" }}
          >
            {" "}
            COMPLETAR COMPRA
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              setOpen2(false);
              // limpiar();
            }}
            sx={{ backgroundColor: "darkRed !important", color: "white !important" }}
          >
            {" "}
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Cargando open2={dialog} />
    </>
  );
};

// export default Tienda;
