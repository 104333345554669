// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// PUBLIMED React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function BuiltByDevelopers() {
  const bgImage =
    "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/soft-ui-design-system/assets/img/desktop.jpg";

  return (
    <MKBox
      display="flex"
      alignItems="center"
      borderRadius="xl"
      my={2}
      py={6}
      sx={{
        backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Grid container item xs={12} lg={6} sx={{ ml: { xs: 0, lg: 6 } }}>
          <MKTypography variant="h4" color="white" fontWeight="bold" mb={2}>
            Acerca de Publimed
          </MKTypography>
          {/* <MKTypography variant="h1" color="white" mb={1}>
            Publimed
          </MKTypography> */}

          <MKTypography variant="body1" color="white" opacity={0.8} mb={2}>
            Publimed es una empresa dedicada al mejoramiento del profesional de la salud, dándoles
            las herramientas necesarias y más convenientes para incrementar sus conocimientos, y
            estar más capacitados para su entorno laboral. También aportar con información actual
            sobre enfermedades que acontecen en nuestro país.
          </MKTypography>
          {/* <MKTypography
            component="a"
            target="_blank"
            rel="noreferrer"
            variant="body2"
            color="white"
            fontWeight="regular"
            sx={{
              display: "flex",
              alignItems: "center",

              "& .material-icons-round": {
                fontSize: "1.125rem",
                transform: `translateX(3px)`,
                transition: "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
              },

              "&:hover .material-icons-round, &:focus .material-icons-round": {
                transform: `translateX(6px)`,
              },
            }}
          ></MKTypography> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default BuiltByDevelopers;
