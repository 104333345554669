/*
=========================================================
* PUBLIMED React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// PUBLIMED React components
import MKBox from "components/MKBox";
import team1 from "assets/images/LIBRO DIGITAL.jpg";

// PUBLIMED React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Misión"
                    description="Tenemos como misión divulgar el conocimiento reflexivo 
                    y crítico de la investigación, a través de publicaciones médicas de 
                    alta calidad, contribuyendo al desarrollo e innovación científica en 
                    el área de salud."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Visión"
                    description="Ser el referente nacional e internacional de publicaciones
                     científicas en el área de la salud, con altos estándares de calidad y 
                     excelencia en académica."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={12}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Objetivos"
                    description="El objetivo de la Empresa Publimed es difundir el conocimiento 
                    científico, a través de los resultados obtenidos, producto de las investigaciones 
                    científicas realizadas, que representen una contribución actualizada para
                     el desarrollo en el área de la salud. Incluye trabajos, productos de investigaciones
                      científicas y reflexiones teóricas que por su realce, deben publicarse,
                       y de esta manera contribuya al futuro de la salud y a los profesionales
                        de todo el mundo."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <MKBox
              component="img"
              src={team1}
              width="100%"
              title="Get insights on Search"
              action={{
                type: "internal",
                route: "pages/company/about-us",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
